/* SmartMenus Start */


/* Carets in collapsible mode (make them look like +/- buttons) */

.navbar-nav.sm-collapsible .sub-arrow {
    position: absolute;
    top: 50%;
    right: 0;
    margin: -0.7em 0.5em 0 0;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: .25rem;
    padding: 0;
    width: 2em;
    height: 1.4em;
    font-size: 1.25rem;
    line-height: 1.2em;
    text-align: center;
}

.navbar-nav.sm-collapsible .sub-arrow::before {
    content: '+';
}

.navbar-nav.sm-collapsible .show>a>.sub-arrow::before {
    content: '-';
}

.navbar-dark .navbar-nav.sm-collapsible .nav-link .sub-arrow {
    border-color: rgba(255, 255, 255, .1);
}


/* make sure there's room for the carets */

.navbar-nav.sm-collapsible .has-submenu {
    padding-right: 3em;
}


/* keep the carets properly positioned */

.navbar-nav.sm-collapsible .nav-link,
.navbar-nav.sm-collapsible .dropdown-item {
    position: relative;
}


/* Nav carets in expanded mode */

.navbar-nav:not(.sm-collapsible) .nav-link .sub-arrow {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: .255em;
    vertical-align: .255em;
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-left: .3em solid transparent;
}


/* point the arrows up for .fixed-bottom navbars */

.fixed-bottom .navbar-nav:not(.sm-collapsible) .nav-link .sub-arrow,
.fixed-bottom .navbar-nav:not(.sm-collapsible):not([data-sm-skip]) .dropdown-toggle::after {
    border-top: 0;
    border-bottom: .3em solid;
}


/* Dropdown carets in expanded mode */

.navbar-nav:not(.sm-collapsible) .dropdown-item .sub-arrow,
.navbar-nav:not(.sm-collapsible):not([data-sm-skip]) .dropdown-menu .dropdown-toggle::after {
    position: absolute;
    top: 50%;
    right: 0;
    width: 0;
    height: 0;
    margin-top: -.3em;
    margin-right: 1em;
    border-top: .3em solid transparent;
    border-bottom: .3em solid transparent;
    border-left: .3em solid;
}


/* make sure there's room for the carets */

.navbar-nav:not(.sm-collapsible) .dropdown-item.has-submenu {
    padding-right: 2em;
}


/* Scrolling arrows for tall menus */

.navbar-nav .scroll-up,
.navbar-nav .scroll-down {
    position: absolute;
    display: none;
    visibility: hidden;
    height: 20px;
    overflow: hidden;
    text-align: center;
}

.navbar-nav .scroll-up-arrow,
.navbar-nav .scroll-down-arrow {
    position: absolute;
    top: -2px;
    left: 50%;
    margin-left: -8px;
    width: 0;
    height: 0;
    overflow: hidden;
    border-top: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid;
    border-left: 7px solid transparent;
}

.navbar-nav .scroll-down-arrow {
    top: 6px;
    border-top: 7px solid;
    border-right: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-left: 7px solid transparent;
}


/* Add some spacing for 2+ level sub menus in collapsible mode */

.navbar-nav.sm-collapsible .dropdown-menu .dropdown-menu {
    margin: .5em;
}


/* Fix SmartMenus sub menus auto width (subMenusMinWidth/subMenusMaxWidth options) */

.navbar-nav:not([data-sm-skip]) .dropdown-item {
    white-space: normal;
}

.navbar-nav:not(.sm-collapsible) .sm-nowrap>li>.dropdown-item {
    white-space: nowrap;
}


/* SmartMenus End */

.navbar {
    background: url('../images/navbar-bg.svg') no-repeat left bottom;
    background-size: cover;
    height: 100px;
    position: fixed;
    padding: 1rem 2rem 0;
    width: 100%;
    z-index: 1000;
    display: inline-flex;
    align-items: baseline;
    &-nav {
        .nav-item {
            .nav-link {
                color: #424242;
                font-size: 1rem;
            }
        }
    }
    &-toggler {
        border: none;
    }
    &-brand {
        img {
            width: auto;
            height: 35px;
        }
    }
    &-collapse {
        flex-direction: column;
        align-items: start;
        .toggle-mg {
            display: none;
        }
        .menu-mg {
            a {
                position: relative;
                display: inline-block;
                text-transform: uppercase;
                text-decoration: none;
                color: #000;
                transition: .5s;
                &::before {
                    content: '';
                    position: absolute;
                    bottom: 10px;
                    left: 10px;
                    width: 10px;
                    height: 10px;
                    border: solid 2px #000;
                    border-width: 0 0 2px 2px;
                    opacity: 0;
                    transition: .5s;
                }
                &:hover:before,
                &.active:before {
                    bottom: -8px;
                    left: -8px;
                    opacity: 1;
                }
                &:after {
                    content: '';
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    width: 10px;
                    height: 10px;
                    border: solid 2px #000;
                    border-width: 2px 2px 0 0;
                    opacity: 0;
                    transition: .5s;
                }
                &:hover:after,
                &.active:after {
                    top: -8px;
                    right: -8px;
                    opacity: 1;
                }
            }
        }
        .menu-mg {
            z-index: 4000;
            a:hover,
            a.active {
                background: linear-gradient(90deg, rgb(76, 140, 198) 0.4%, rgb(124, 200, 235) 99%);
                color: white;
                margin: 0 1rem;
            }
            &.changeColor {
                .nav-link {
                    color: #fff;
                }
            }
        }
        .nav-link {
            padding: .4rem 1rem;
            margin: 0 .5rem;
            font-weight: 600;
        }
    }
    @include media-breakpoint-down(md) {
        display: flex;
        padding: 1rem 1rem;
        &-social {
            .nav-link {
                padding-right: 0;
                padding-left: 0;
            }
        }
        &-collapse {
            z-index: 1000;
            box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.375);
            position: fixed;
            top: 2rem;
            right: 1rem;
            left: 1rem;
            background-color: #fff;
            padding: 10px;
            border-radius: 15px;
            .toggle-mg {
                display: block;
                margin: 5px;
                float: right;
                .fas {
                    font-size: x-large;
                    color: #0F3E95;
                }
            }
            .menu-mg {
                margin-top: 2rem;
                padding-bottom: 10px;
                .nav-link {
                    padding: 10px 5px;
                    margin-bottom: 10px;
                    &.active,
                    &:focus {
                        color: #fff;
                        margin-bottom: 10px;
                    }
                }
            }
            .navbar-social {
                border-top: dotted 2px #fcba03;
                padding-top: 10px;
                i {
                    margin-right: 5px;
                    display: inline-block;
                    &.fa-facebook-square {
                        color: #1765d1;
                    }
                    &.fa-google-plus-square {
                        color: #d93b14;
                    }
                }
            }
        }
    }
    @include media-breakpoint-up(lg) {
        &-social {
            position: fixed;
            bottom: 64px;
            right: 0;
            .nav-link {
                writing-mode: vertical-rl;
                padding-right: .5rem;
                padding-left: .5rem;
            }
        }
    }
    @media screen and (min-width: 1501px) {
        height: 125px;
    }
}