* {
    scroll-behavior: smooth;
    outline: none;
    cursor: none;
    // font-family: 'Prompt', sans-serif;
    // font-family: 'Open Sans', sans-serif;
    letter-spacing: 0.2px;
    a {
        &:hover {
            text-decoration: none;
        }
    }
    &:focus,
    input::-moz-focus-inner,
    button::-moz-focus-inner {
        outline: 0;
    }
}

body {
    background: white;
}

h1,
h2,
.Prompt-title {
    font-family: 'Prompt', sans-serif;
    line-height: 1.1;
    letter-spacing: 1.1px;
}


/*- 頁碼 -*/

.pagination {
    font-weight: 500;
    margin: 2rem 0;
}


/*- 輸入框Placeholder自訂顏色 -*/

.form-control {
    &::-webkit-input-placeholder {
        color: $gray-400;
    }
    &input::-moz-placeholder {
        color: $gray-400;
    }
    &:-ms-input-placeholder {
        color: $gray-400;
    }
}


/*- 圖片比例 -*/

.img-by {
    position: relative;
    width: 100%;
    height: 0;
    overflow: hidden;
    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 102%;
        height: auto;
    }
    &-1by1 {
        padding-bottom: 100%;
    }
    &-4by3 {
        padding-bottom: 75%;
    }
    &-16by9 {
        padding-bottom: 56.25%;
    }
    &-5by2 {
        padding-bottom: 40%;
    }
}

.gotop {
    position: fixed;
    bottom: 0px;
    right: 5px;
    z-index: 10;
    display: inline-block;
    padding: 15px;
    @include opacity-hover-ani;
    img {
        width: 40px;
        height: 40px;
    }
}


/*- Header -*/

.header {
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    @include media-breakpoint-up(lg) {
        // position: fixed;
        // top: 16px;
        // left: 16px;
        &.sticky {
            position: fixed;
            background: rgba(265, 265, 265, 0.9);
            width: 100%;
            box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.3);
        }
    }
    @include media-breakpoint-down(md) {
        &.sticky {
            position: sticky;
            top: 0;
            left: 0;
            z-index: 1000;
            background-color: rgba(265, 265, 265, 0.9);
            box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.3);
        }
    }
}


/*- Footer -*/

.footer {
    background: linear-gradient(90deg, rgb(22, 68, 153) 14%, rgba(126, 203, 237, 1) 99%);
    .footer-content {
        background: url('../images/footerbg-left.png') no-repeat center left;
        padding: 16px 0;
        @include flex-center;
        .footer-logo {
            width: 60px;
        }
        p {
            margin: 8px 0 0 0;
            color: #ffffff;
            @include opacity-hover-ani;
        }
    }
    .footer-social {
        display: flex;
        img {
            width: 30px;
        }
        a {
            display: block;
            padding: 6px 4px 2px 4px;
            margin: 2px 2px 0px 2px;
            @include opacity-hover-ani;
        }
    }
    .footer-copyright {
        color: #ffffff;
        background: $primary;
        padding: 1rem 0;
        display: flex;
        justify-content: center;
        font-size: 10px;
        font-weight: 300;
        letter-spacing: 0.5px;
    }
}

// pagination //
.pagination-sec {
    display: flex;
    justify-content: center;
    margin: 2rem 0 .5rem;
    .pagination {
        display: flex;
        align-items: center;
    }
    .page-item {
        margin-left: 1rem;
        &.active {
            .page-link {
                background: $primary !important;
                color: white;
                border-color: $primary;
            }
        }
        .page-link {
            border: none;
            @include opacity-hover-ani;
            background: transparent;
        }
    }
}