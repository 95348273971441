@mixin list-unstyled {
    list-style: none;
    padding: 0;
    margin: 0;
}

@mixin text-overflow {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

@mixin position-center {
    position: absolute;
    width: 100%;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@mixin flex-center {
    display: flex;
    flex-direction: column;
    align-items: center;
}

//各頁內文title裝飾
@mixin h2-deco-border {
    &::before {
        content: '';
        background: #BAD3EB;
        background-size: 100%;
        width: 100%;
        height: 2px;
        position: absolute;
        left: 0%;
        bottom: -10%;
    }
}

@mixin opacity-hover-ani {
    transition: all .15s linear;
    &:hover {
        opacity: 80%;
    }
}

@mixin gallery-hover-ani {
    &:hover {
        .gallery-outside {
            img {
                transform: translate(-50%, -50%) scale(1.2);
                transition: all 0.3s ease-in-out;
            }
        }
    }
}

// 各頁banner（除了首頁）
@mixin banner-page {
    position: relative;
    overflow: hidden;
}

@mixin banner-wrap {
    background-size: cover;
    height: 95vh;
    @media screen and (max-width: 1280px) {
        height: 70vh;
    }
    @include media-breakpoint-down(md) {
        height: 85vh;
    }
    @include media-breakpoint-down(sm) {
        height: 100vh;
    }
    .banner-wrap {
        height: 100%;
        display: flex;
        align-items: center;
        @include media-breakpoint-down(lg) {
            align-items: initial;
        }
        .banner-title {
            position: relative;
            margin-left: 15%;
            @include media-breakpoint-down(lg) {
                margin-top: 20%;
            }
            @include media-breakpoint-down(sm) {
                margin-top: 35%;
            }
            .banner-text {
                font-weight: 500;
                font-style: italic;
                color: white;
                font-size: 5rem;
                letter-spacing: 4px;
                line-height: 3.5rem;
                @include media-breakpoint-down(md) {
                    font-size: 3.8rem;
                }
                @include media-breakpoint-down(sm) {
                    font-size: 2.8rem;
                }
            }
            &::before {
                content: '';
                background: #fff;
                position: absolute;
                width: 1180px;
                height: 2px;
                bottom: -80%;
                left: -50px;
                @media screen and (max-width: 1440px) {
                    width: 1000px;
                    left: -40px;
                }
                @include media-breakpoint-down(lg) {
                    display: none;
                }
            }
        }
    }
    .banner-deco {
        position: absolute;
        left: 5%;
        top: 50vh;
        @media screen and (max-width: 1280px) {
            top: 40vh;
        }
        .decoText {
            position: relative;
            color: white;
            font-size: .8rem;
            margin: 0;
            writing-mode: vertical-rl;
            @include media-breakpoint-down(md) {
                font-size: .6rem;
            }
            &::before {
                content: '';
                position: absolute;
                top: -50vh;
                left: 50%;
                width: 2px;
                height: 45vh;
                background: #fff;
                @media screen and (max-width: 1280px) {
                    top: -45vh;
                    height: 40vh;
                }
            }
        }
    }
}

//角色
@mixin banner-character-position {
    position: absolute;
    width: 50%;
    top: 0%;
    right: 3%;
}

//角色動畫
@mixin banner-move-ani {
    animation-name: banner-move-ani;
    animation-duration: 4.5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    @keyframes banner-move-ani {
        0% {
            transform: translate(0px, 0px);
        }
        50% {
            transform: translate(-30px, -35px);
        }
        100% {
            transform: translate(0px, 0px);
        }
    }
}

// 各頁banner（除了首頁）
//card
@mixin card-title-center {
    @include position-center;
    h3 {
        text-transform: uppercase;
        color: white;
        font-size: 1.5rem;
        font-weight: 600;
        text-align: center;
        margin-bottom: 0;
    }
}