/*- Banner -*/

.idxBanner {
    height: 720px;
    position: relative;
    .banner-wrap {
        height: 100%;
        position: relative;
        display: flex;
        align-items: center;
        overflow: hidden;
        background: radial-gradient(ellipse at center, rgba(255, 255, 255, 0.2) 0%, rgba(0, 0, 0, 0) 50%), /* niebieski */
        radial-gradient(ellipse at center, rgba(255, 151, 82, 0.3) 0%, rgba(0, 0, 0, 0) 60%), /*czerwony */
        radial-gradient(ellipse at center, rgba(141, 233, 218, 0.3) 0%, rgba(0, 0, 0, 0) 60%);
        /*zielony */
        background-repeat: no-repeat, no-repeat, no-repeat;
        background-size: 900px 900px, 900px 900px, 900px 900px;
        background-attachment: fixed;
        animation: bganimation 6s linear infinite;
        @keyframes bganimation {
            0% {
                background-position: -100% -100%, 0% 200%, 200% 200%, 200% -100%;
            }
            50% {
                background-position: 50% 100%, 100% 0%, -200% 100%, 0% 100%;
            }
            100% {
                background-position: -100% -100%, 0% 200%, 200% 200%, 200% -100%;
            }
        }
        &::before {
            content: '';
            background: url('../images/idxBanner-bg.jpg')no-repeat bottom;
            background-size: cover;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            animation-name: Scale;
            animation-duration: 60s;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
            @keyframes Scale {
                0% {
                    transform: scale(1);
                }
                50% {
                    transform: scale(1.5);
                }
                100% {
                    transform: scale(1);
                }
            }
        }
    }
    .banner-title {
        padding-left: 3%;
        display: inline-block;
        position: relative;
        width: 100%;
        .banner-text {
            font-weight: 500;
            font-style: italic;
            font-size: 3.5rem;
            letter-spacing: 2px;
            line-height: 3.5rem;
            color: white;
            position: relative;
            width: 100%;
            text-shadow: 0.05em 0 0 rgba(38, 211, 75, 0.952), -2px -0.05em 0 rgba(36, 76, 255, 0.75), 2px 0.05em 0 rgba(216, 15, 15, 0.75);
            animation: glitch 500ms infinite;
            animation-name: Swing;
            /*動畫名稱，需與 keyframe 名稱對應*/
            animation-duration: 2s;
            /*動畫持續時間，單位為秒*/
            animation-timing-function: linear;
            animation-iteration-count: infinite;
            @keyframes Swing {
                0% {
                    transform: translate(0, 0);
                }
                20% {
                    transform: translate(-4px, 2px);
                }
                50% {
                    transform: translate(-2px, -3px);
                }
                70% {
                    transform: translate(-4px, 2px);
                }
                100% {
                    transform: translate(0, 0);
                }
            }
            p {
                position: absolute;
                top: 0;
                left: 0;
                &:first-child {
                    animation: glitch 850ms infinite;
                    clip-path: polygon(0 20%, 100% 15%, 100% 75%, 0 90%);
                    transform: translate(-2px, -0.0125em);
                    /* color: green; */
                    opacity: 0.8;
                }
                &:last-child {
                    animation: glitch 550ms infinite;
                    clip-path: polygon(0 2%, 100% 0%, 100% 90%, 0 100%);
                    transform: translate(0.0125em, 2px);
                    /* color: red; */
                    opacity: 0.8;
                }
            }
            @keyframes glitch {
                0% {
                    text-shadow: 2px 0 0 rgba(38, 211, 75, 0.952), -2px -2px 0 rgba(36, 76, 255, 0.75), -2px 2px 0 rgba(216, 15, 15, 0.75);
                }
                14% {
                    text-shadow: 2px 0 0 rgba(38, 211, 75, 0.952), -2px -2px 0 rgba(36, 76, 255, 0.75), -2px 2px 0 rgba(216, 15, 15, 0.75);
                }
                15% {
                    text-shadow: -2px -2px 0 rgba(38, 211, 75, 0.952), 2px 2px 0 rgba(36, 76, 255, 0.75), -2px -2px 0 rgba(216, 15, 15, 0.75);
                }
                49% {
                    text-shadow: -2px -2px 0 rgba(38, 211, 75, 0.952), 2px 2px 0 rgba(36, 76, 255, 0.75), -2px -2px 0 rgba(216, 15, 15, 0.75);
                }
                50% {
                    text-shadow: 2px 2px 0 rgba(38, 211, 75, 0.952), 2px 0 0 rgba(36, 76, 255, 0.75), 0 -2px 0 rgba(216, 15, 15, 0.75);
                }
                99% {
                    text-shadow: 2px 2px 0 rgba(38, 211, 75, 0.952), 2px 0 0 rgba(36, 76, 255, 0.75), 0 -2px 0 rgba(216, 15, 15, 0.75);
                }
                100% {
                    text-shadow: -2px 0 0 rgba(38, 211, 75, 0.952), -2px -2px 0 rgba(36, 76, 255, 0.75), -2px -2px 0 rgba(216, 15, 15, 0.75);
                }
            }
        }
    }
    .idxBanner-pic {
        position: absolute;
        top: 10%;
        right: 5%;
        width: 48%;
        animation-name: BannerMoveToBottom;
        /*動畫名稱，需與 keyframe 名稱對應*/
        animation-duration: 4.5s;
        /*動畫持續時間，單位為秒*/
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
        // z-index: 1000;
        @keyframes BannerMoveToBottom {
            0% {
                transform: translate(0px, 0px);
            }
            50% {
                transform: translate(-30px, -50px);
            }
            100% {
                transform: translate(0px, 0px);
            }
        }
    }
    @media screen and (max-width: 576px) {
        height: 650px;
        .banner-wrap {
            align-items: initial;
            padding-top: 150px;
        }
        .banner-title {
            padding-left: 8%;
            width: 100%;
            .banner-text {
                font-size: 2.5rem;
                line-height: 3.5rem;
                p {
                    &:first-child {
                        clip-path: polygon(0 20%, 100% 15%, 100% 100%, 0 90%);
                    }
                    &:last-child {
                        clip-path: polygon(0 2%, 100% 0%, 100% 100%, 0 100%);
                    }
                }
            }
        }
        .idxBanner-pic {
            top: 38%;
            right: 10%;
            width: 375px;
            z-index: 1;
        }
    }
    @media screen and (min-width: 1501px) {
        height: 900px;
        .banner-title {
            padding-left: 6%;
            .banner-text {
                font-size: 4.5rem;
                line-height: 5.5rem;
            }
        }
        .idxBanner-pic {
            width: 47%;
        }
    }
    @media screen and (max-width: 1300px) {
        height: 650px;
        .banner-title {
            width: 60%;
            .banner-text {
                font-size: 3.5rem;
                line-height: 4.5rem;
            }
        }
        .idxBanner-pic {
            width: 55%;
        }
    }
    @media screen and (max-width: 1080px) {
        .banner-wrap {
            align-items: initial;
            padding-top: 15%;
        }
        .banner-title {
            padding-left: 8%;
            width: 50%;
            .banner-text {
                font-size: 3rem;
                line-height: 4rem;
                p {
                    &:first-child {
                        clip-path: polygon(0 20%, 100% 15%, 100% 100%, 0 90%);
                    }
                    &:last-child {
                        clip-path: polygon(0 2%, 100% 0%, 100% 100%, 0 100%);
                    }
                }
            }
        }
        .idxBanner-pic {
            top: 15%;
            right: 5%;
            width: 60%;
        }
    }
    @media screen and (max-width: 820px) {
        height: 650px;
        .banner-wrap {
            padding-top: 150px;
        }
        .banner-title {
            width: 100%;
            .banner-text {
                font-size: 2.5rem;
                line-height: 3.5rem;
            }
        }
        .idxBanner-pic {
            top: 38%;
            right: 10%;
            width: 475px;
        }
    }
    @media screen and (max-width: 576px) {
        .banner-wrap {
            align-items: initial;
            padding-top: 130px;
        }
        .banner-title {
            .banner-text {
                font-size: 2rem;
                line-height: 3rem;
            }
        }
        .idxBanner-pic {
            top: 40%;
            right: 5%;
            width: 400px;
            animation-duration: 3s;
            @keyframes BannerMoveToBottom {
                50% {
                    transform: translate(-10px, -15px);
                }
            }
        }
    }
}


/*- About -*/

.idxAbout {
    height: auto;
    position: relative;
    .marquee-top,
    .marquee-bottom {
        position: absolute;
        color: rgba($secondary, $alpha: 0.15);
        font-size: 18rem;
        font-weight: bold;
        @media screen and (max-width: 1280px) {
            font-size: 14rem;
        }
        @include media-breakpoint-down(sm) {
            font-size: 8rem;
        }
    }
    .marquee-top {
        top: 5%;
        @include media-breakpoint-down(md) {
            top: 10%;
        }
        @include media-breakpoint-down(sm) {
            top: 20%;
        }
    }
    .marquee-bottom {
        bottom: 5%;
        @include media-breakpoint-down(md) {
            bottom: 0%;
        }
        @include media-breakpoint-down(sm) {
            bottom: 20%;
        }
    }
}

.about-wrap {
    position: relative;
    width: 100%;
    height: 800px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    .about-square {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 65%;
        height: 55%;
        border: solid 2px rgba(126, 182, 237, 0.45);
        background: rgba(255, 255, 255, .6);
        backdrop-filter: blur(2px);
        // position: relative;
        .square-row {
            @include make-row();
            margin: 0;
            height: 100%;
            width: 100%;
            .about-txt {
                @include make-col(6);
                @include make-col-ready();
                // text-align: justify;
                height: 100%;
                padding-top: 1rem;
                padding-right: 2rem;
                display: flex;
                flex-direction: column;
                justify-content: center;
                .about-title {
                    font-size: 3rem;
                    font-weight: 600;
                    margin-bottom: 2rem;
                }
                p:nth-child(2) {
                    font-size: 1.3rem;
                }
            }
            .about-pic {
                @include make-col(6);
                @include make-col-ready();
                height: 100%;
                .witch1-pic {
                    transform: translateY(-7rem);
                    position: relative;
                }
            }
        }
    }
    @media screen and (min-width: 1501px) {
        .about-square {
            width: 60%;
            height: 60%;
            .square-row {
                .about-txt {
                    font-size: 1.2rem;
                    width: 55%;
                    padding-top: 1rem;
                    padding-right: 2rem;
                    .about-title {
                        font-size: 3.3rem;
                    }
                    p:nth-child(2) {
                        font-size: 1.5rem;
                    }
                }
                .about-pic {
                    width: 455px;
                    .witch1-pic {
                        .book-Deco {
                            position: absolute;
                            top: 25%;
                            right: -3%;
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1440px) {
        .witch1-pic {
            .about-pic {
                transform: translateY(-3rem);
            }
        }
    }
    @media screen and (max-width: 1300px) {
        height: 650px;
        .about-square {
            width: 65%;
            height: 50%;
            .square-row {
                .about-txt {
                    .about-title {
                        font-size: 2.8rem;
                    }
                    p:nth-child(2) {
                        font-size: 1rem;
                    }
                }
                .about-pic {
                    transform: translateY(2rem);
                    z-index: 1;
                    .witch1-pic {
                        .book-Deco {
                            width: 45%;
                            right: -5%;
                            top: 25%;
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1080px) {
        .about-square {
            width: 65%;
            height: 55%;
            .square-row {
                .about-txt {
                    width: 60%;
                }
                .about-pic {
                    z-index: 1;
                    .witch1-pic {
                        width: 110%;
                        transform: translateY(-4rem);
                        .book-Deco {
                            right: -4%;
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 820px) {
        height: 500px;
        .about-square {
            width: 75%;
            height: 55%;
            .square-row {
                .about-txt {
                    .about-title {
                        font-size: 2rem;
                    }
                    p:nth-child(2) {
                        font-size: 1rem;
                        text-align: left;
                    }
                }
                .about-pic {
                    z-index: 1;
                    .witch1-pic {
                        transform: translateY(-5rem);
                        width: 250px;
                        .book-Deco {
                            right: -5%;
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 576px) {
        height: 800px;
        .about-square {
            width: 75%;
            height: 65%;
            padding-top: 2rem;
            .square-row {
                .about-txt {
                    @include make-col(12);
                    @include make-col-ready();
                    height: 40%;
                    order: 1;
                    padding: 2rem;
                    .about-title {
                        font-size: 2rem;
                        text-align: center;
                    }
                    p:nth-child(2) {
                        font-size: 1rem;
                        text-align: center;
                    }
                }
                .about-pic {
                    @include make-col(12);
                    @include make-col-ready();
                    height: 60%;
                    z-index: 1;
                    order: 2;
                    .witch1-pic {
                        transform: translate(0rem, -5rem);
                        width: auto;
                        height: 100%;
                        text-align: center;
                        img {
                            width: 250px;
                        }
                        .book-Deco {
                            top: 35%;
                            right: -15%;
                        }
                    }
                }
            }
        }
    }
}


/*- idxServices -*/

.idxService {
    background: url('../images/service-graybg.png') no-repeat top left;
    background-size: cover;
    width: 100%;
    height: 1150px;
    position: relative;
    .service-wrap {
        padding-top: 10%;
        padding-left: 5%;
        width: 50%;
        .service-title {
            h2 {
                font-size: 3.5rem;
                font-weight: 700;
                letter-spacing: 3px;
                color: white;
            }
        }
        .service-content {
            margin-top: 3rem;
            .service-row {
                @include make-row();
                margin: 0;
                flex-wrap: nowrap;
                .service-item {
                    @include make-col(4);
                    @include make-col-ready();
                    &:nth-child(2) {
                        transform: translateY(15%);
                    }
                    .service-box {
                        background: white;
                        padding: 2rem 1rem;
                        // border-radius: 3px;
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        flex-direction: column;
                        align-items: center;
                        .item-icon {
                            width: 60%;
                            text-align: center;
                        }
                        .item-txt {
                            text-align: center;
                            font-size: 1rem;
                            line-height: 1.2rem;
                            font-weight: 400;
                            color: #424242;
                            padding-top: .5rem;
                            p {
                                margin: 0;
                            }
                        }
                        &:hover {
                            transform: translateY(-5%);
                            box-shadow: 0px 0px 30px 0.5px #ffffff60;
                            transition: ease-in-out .3s;
                            &:nth-child(2) {
                                transform: translateY(10%);
                            }
                            .item-txt {
                                p {
                                    color: #5098E0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .service-pic {
        z-index: -1;
        transform: translate(-3%, -5%);
        position: absolute;
        top: 12%;
        right: 10%;
        width: 35%;
        .witch {
            position: relative;
            .service-picDeco1 {
                position: absolute;
                top: -5%;
                left: 10%;
            }
            .service-picDeco2 {
                position: absolute;
                top: 25%;
                right: 5%;
                animation-name: Moving;
                /*動畫名稱，需與 keyframe 名稱對應*/
                animation-duration: 2.5s;
                /*動畫持續時間，單位為秒*/
                animation-timing-function: ease-in-out;
                animation-iteration-count: infinite;
                @keyframes Moving {
                    0% {
                        transform: translate(0px, 0px);
                    }
                    50% {
                        transform: translate(0px, -35px);
                    }
                    100% {
                        transform: translate(0px, 0px);
                    }
                }
            }
        }
    }
    &::before {
        content: '';
        background: url('../images/service-bluebg.png')no-repeat top;
        background-size: 100%;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 1%;
        right: 0;
        z-index: -1;
    }
    @media screen and (min-width: 1501px) {
        .service-wrap {
            width: 55%;
            .service-title {
                h2 {
                    font-size: 4.5rem;
                }
            }
            .service-content {
                .service-item {
                    .service-box {
                        padding: 1.5rem;
                        width: 300px;
                        height: 300px;
                        .item-txt {
                            font-size: 1.5rem;
                            line-height: 1.4;
                            padding-top: 1rem;
                        }
                    }
                }
            }
        }
        .service-pic {
            top: 15%;
            right: 10%;
            width: 30%;
            .witch {
                .service-picDeco1 {
                    top: -10%;
                }
                // .service-picDeco2 {
                //     top: 15%;
                //     right: -10%;
                // }
            }
        }
    }
    @media screen and (max-width: 1300px) {
        .service-wrap {
            padding-left: 5%;
            padding-top: 8%;
            width: 50%;
        }
        .service-pic {
            top: 5%;
            right: 10%;
            width: 35%;
            .witch {
                .service-picDeco1 {
                    top: -5%;
                    img {
                        width: 70%;
                    }
                }
                .service-picDeco2 {
                    img {
                        width: 70%;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1440px) {
        height: 850px;
    }
    @media screen and (max-width: 1080px) {
        height: 800px;
        .service-wrap {
            padding-left: 5%;
            padding-top: 15%;
            width: 60%;
        }
        .service-pic {
            top: 4%;
            right: 10%;
            width: 42%;
            .witch {
                .service-picDeco1 {
                    width: 100%;
                    top: 0%;
                }
                .service-picDeco2 {
                    top: 20%;
                }
            }
        }
    }
    @media screen and (max-width: 820px) {
        margin-top: 100px;
        height: 700px;
        .service-wrap {
            padding-left: 5%;
            padding-top: 20%;
            width: 70%;
            .service-title {
                h2 {
                    font-size: 3.5rem;
                }
            }
            .service-content {
                .service-item {
                    .service-box {
                        padding: 1rem;
                        .item-txt {
                            font-size: 1rem;
                            line-height: 1.4;
                            padding-top: 1rem;
                        }
                    }
                }
            }
        }
        .service-pic {
            top: 4%;
            right: 10%;
            width: 45%;
            .witch {
                .service-picDeco2 {
                    top: 20%;
                    width: 30%;
                }
            }
        }
    }
    @media screen and (max-width: 576px) {
        background: url(../images/service-graybg.png) no-repeat top left -600px;
        background-size: cover;
        margin-top: 0px;
        height: 1300px;
        .service-wrap {
            padding: 0 1rem;
            padding-top: 70%;
            width: 100%;
            .service-title {
                h2 {
                    font-size: 3.5rem;
                }
            }
            .service-content {
                .service-row {
                    flex-wrap: wrap;
                    justify-content: center;
                    .service-item {
                        @include make-col(8);
                        @include make-col-ready();
                        padding: .5rem 0;
                        &:nth-child(2) {
                            transform: translateY(0);
                        }
                        .service-box {
                            padding: 1rem;
                            height: 220px;
                            .item-txt {
                                font-size: 1rem;
                                line-height: 1.4;
                                padding-top: 1rem;
                            }
                        }
                    }
                }
            }
        }
        .service-pic {
            top: 6%;
            right: 10%;
            width: 90%;
            // .witch {
            //     // .service-picDeco1 {
            //     //     width: 100%;
            //     //     top: -10%;
            //     // }
            //     .service-picDeco2 {
            //         // top: 25;
            //         width: 30%;
            //     }
            // }
        }
        &::before {
            content: '';
            background: url(../images/service-bluebg.png) no-repeat top left -300px;
        }
    }
}


/*- idxContact -*/

.idxContact {
    background: url('../images/idx-contact-bg.png')no-repeat top left;
    background-size: cover;
    width: 100%;
    height: 900px;
    margin-top: -30%;
    position: relative;
    overflow: hidden;
    .contact-pic {
        position: absolute;
        top: 30%;
        left: 10%;
        width: 25%;
        animation-name: contact-scale;
        /*動畫名稱，需與 keyframe 名稱對應*/
        animation-duration: 2.5s;
        /*動畫持續時間，單位為秒*/
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
        @keyframes contact-scale {
            0% {
                transform: scale(.9);
            }
            50% {
                transform: scale(1);
            }
            100% {
                transform: scale(.9);
            }
        }
        .contact-pic-wrap {
            position: relative;
            .contact-deco1 {
                width: 120%;
                height: 120%;
                position: absolute;
                right: -14%;
                top: 6%;
                z-index: -1;
                .idxContact-deco1 {
                    animation: orbit-1 4s linear infinite;
                    position: absolute;
                    top: 0%;
                    left: 0;
                    transform: rotate(100deg);
                }
                @keyframes orbit-1 {
                    0% {
                        transform: rotate(0deg);
                    }
                    100% {
                        transform: rotate(-360deg);
                    }
                }
            }
            .contact-deco2 {
                width: 100%;
                height: auto;
                position: absolute;
                right: -10%;
                top: 48%;
                animation: Moving 2s ease-in-out infinite;
                .contact-deco2inner {
                    position: relative;
                    &::before {
                        content: '';
                        background: url('../images/idxContact-deco3.png') no-repeat;
                        background-size: 100%;
                        width: 230px;
                        height: 180px;
                        position: absolute;
                        bottom: 0%;
                        left: -3%;
                        animation: Spark 1.3s linear infinite;
                        @keyframes Spark {
                            0% {
                                opacity: 1;
                            }
                            50% {
                                opacity: 0.4;
                            }
                            100% {
                                opacity: 1;
                            }
                        }
                    }
                }
                .idxContact-deco2 {
                    width: 40%;
                    position: relative;
                }
                @keyframes Moving {
                    0% {
                        transform: translate(0px, 0px);
                    }
                    50% {
                        transform: translate(0px, -30px);
                    }
                    100% {
                        transform: translate(0px, 0px);
                    }
                }
            }
        }
    }
    .contact-wrap {
        position: absolute;
        right: 13%;
        bottom: 25%;
        .contact-txt {
            p {
                font-size: 2.5rem;
                font-weight: 800;
                letter-spacing: 3px;
            }
        }
        .contact-cta {
            display: inline-block;
            transform: translateY(10%);
            margin-top: 3rem;
            animation-name: Scaling;
            /*動畫名稱，需與 keyframe 名稱對應*/
            animation-duration: 1s;
            /*動畫持續時間，單位為秒*/
            animation-timing-function: ease-in-out;
            animation-iteration-count: infinite;
            @keyframes Scaling {
                0% {
                    transform: scale(1);
                }
                50% {
                    transform: scale(1.1);
                    opacity: .6;
                }
                100% {
                    transform: scale(1);
                }
            }
            .contact-btn {
                padding: .8rem;
                font-size: 1rem;
                background: $primary;
                color: white;
                // text-decoration: none;
            }
        }
    }
    @media screen and (max-width: 1300px) {
        height: 700px;
        margin-top: -37%;
        .contact-pic {
            top: 35%;
            left: 5%;
            .contact-pic-wrap {
                .contact-deco2 {
                    .contact-deco2inner {
                        &::before {
                            bottom: -20%;
                            left: -10%;
                        }
                    }
                }
            }
        }
        .contact-wrap {
            right: 5%;
        }
    }
    @media screen and (max-width: 1440px) {
        height: 650px;
    }
    @media screen and (max-width: 1080px) {
        // height: 650px;
        .contact-pic {
            .contact-pic-wrap {
                .contact-deco2 {
                    .contact-deco2inner {
                        &::before {
                            bottom: 15%;
                            left: -5%;
                            width: 150px;
                            height: 100px;
                        }
                    }
                    @keyframes Moving {
                        0% {
                            transform: translate(0px, 0px);
                        }
                        50% {
                            transform: translate(0px, -10px);
                        }
                        100% {
                            transform: translate(0px, 0px);
                        }
                    }
                }
            }
        }
        .contact-wrap {
            width: 50%;
            bottom: 22%;
            .contact-txt {
                p {
                    font-size: 2rem;
                }
            }
            .contact-cta {
                margin-top: 1.5rem;
            }
        }
    }
    @media screen and (max-width: 820px) {
        margin-top: -40%;
        .contact-pic {
            top: 35%;
            left: 5%;
            width: 40%;
        }
        .contact-wrap {
            right: 5%;
            width: 40%;
            bottom: 22%;
            .contact-txt {
                p {
                    font-size: 1.6rem;
                }
            }
        }
    }
    @media screen and (max-width: 576px) {
        height: 800px;
        margin-top: -250px;
        .contact-pic {
            top: 25%;
            left: 10%;
            width: 280px;
        }
        .contact-wrap {
            right: 0%;
            width: 100%;
            bottom: 5%;
            padding: 0 2rem;
            .contact-txt {
                p {
                    font-size: 1.5rem;
                    text-align: center;
                }
            }
            .contact-cta {
                margin-top: 1.5rem;
                display: flex;
                justify-content: center;
            }
        }
    }
}


/*----------------------------------------------------- Services Page -------------------------------------------------------*/


/*- Services Page - Banner -*/

.servicePage_banner {
    @include banner-page;
    background: transparent linear-gradient(250deg, #081E3C 0%, #0A1F3E 30%, #8E272A 100%);
    .servicePage-bannerWrap {
        background: url('../images/servicePage-bannerBg.png') no-repeat left top;
        @include banner-wrap;
    }
    .servicePage-pic {
        @include banner-character-position;
        @include banner-move-ani;
        width: 65%;
        top: 10%;
        right: -15%;
        @media screen and (max-width: 1440px) {
            width: 55%;
            right: -10%;
            top: 10%;
        }
        @include media-breakpoint-down(lg) {
            width: 90%;
            top: 30%;
            right: 0%;
        }
        @include media-breakpoint-down(sm) {
            top: 35%;
            right: -10%;
        }
    }
}


/*- Services Page - Capability -*/

.servicePage_cap {
    position: relative;
    overflow: hidden;
    margin-top: -13%;
    background: url('../images/servicePage-capBg.png') no-repeat top right;
    background-size: cover;
    .servicePage-capWrap {
        padding: 10% 0 5%;
        .cap-title {
            display: flex;
            justify-content: flex-end;
            padding-right: 4rem;
            h2 {
                font-size: 3.5rem;
                font-weight: 600;
                position: relative;
                @include h2-deco-border;
            }
        }
        .cap-content {
            margin: 5rem 0 0;
            margin-left: 0 !important;
            margin-right: 0 !important;
            @include make-row();
            // display: flex;
            // justify-content: center;
            // align-items: center;
            // position: relative;
            .cap-wrap {
                @include make-col(2.4);
                @include make-col-ready();
                // margin: 0 1rem;
                position: relative;
                overflow: hidden;
                .cap-item {
                    background: url('../images/servicePage-capitemBlue.png') no-repeat top;
                    background-size: cover;
                    padding: 1rem;
                    // width: 250px;
                    height: 250px;
                    // border-radius: 3px;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    align-items: center;
                    position: relative;
                    .item-icon {
                        width: 60%;
                    }
                    .item-txt {
                        text-align: center;
                        font-size: 1rem;
                        line-height: 1.2rem;
                        font-weight: 400;
                        color: white;
                        padding-top: 1rem;
                        p {
                            margin: 0;
                        }
                    }
                }
                &::before {
                    position: absolute;
                    top: 0;
                    left: -75%;
                    z-index: 2;
                    display: block;
                    content: '';
                    width: 50%;
                    height: 100%;
                    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
                    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
                    -webkit-transform: skewX(-25deg);
                    transform: skewX(-25deg);
                }
                &:hover {
                    .cap-item {
                        transform: translateY(10px);
                        transition: ease-in .3s;
                    }
                    &::before {
                        -webkit-animation: shine .75s;
                        animation: shine .75s;
                    }
                    @-webkit-keyframes shine {
                        100% {
                            left: 125%;
                        }
                    }
                    @keyframes shine {
                        100% {
                            left: 125%;
                        }
                    }
                }
                &:nth-child(even) {
                    .cap-item {
                        background: url('../images/servicePage-capitemBlack.png') no-repeat top;
                        background-size: cover;
                    }
                }
            }
            .servicePage-capTxt {
                @include make-col(8);
                @include make-col-ready();
                margin-top: 3rem;
                // position: absolute;
                // left: 13%;
                // bottom: 20%;
                p {
                    margin: 0;
                    font-size: 1.1rem;
                    font-weight: 500;
                    // text-align: justify;
                }
            }
        }
    }
    @media screen and (max-width: 1300px) {
        .servicePage-capWrap {
            .cap-title {
                h2 {
                    font-size: 3rem;
                }
            }
            .cap-content {
                .cap-wrap {
                    .cap-item {
                        height: 220px;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1080px) {
        margin-top: -120px;
        .servicePage-capWrap {
            padding: 150px 0 5%;
            .cap-content {
                justify-content: center;
                .cap-wrap {
                    @include make-col(4);
                    @include make-col-ready();
                    .cap-item {
                        height: 280px;
                        margin-bottom: 1.5rem;
                        padding: 1.5rem 1rem;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 820px) {
        margin-top: -150px;
        .servicePage-capWrap {
            .cap-title {
                padding-right: 1rem;
            }
            .cap-content {
                justify-content: center;
                margin: 1rem 0 0;
                .cap-wrap {
                    @include make-col(6);
                    @include make-col-ready();
                    .cap-item {
                        height: 280px;
                        margin-bottom: 1.5rem;
                        padding: 1.5rem 1rem;
                    }
                    &:nth-child(1) {
                        order: 2;
                    }
                    &:nth-child(2) {
                        order: 3;
                    }
                    &:nth-child(3) {
                        order: 4;
                    }
                    &:nth-child(4) {
                        order: 5;
                    }
                    &:nth-child(5) {
                        order: 6;
                    }
                }
                .servicePage-capTxt {
                    @include make-col(12);
                    @include make-col-ready();
                    margin-top: 1rem;
                    margin-bottom: 2rem;
                    order: 1;
                }
            }
        }
    }
    @media screen and (max-width: 576px) {
        margin-top: -180px;
        .servicePage-capWrap {
            padding: 100px 0 5%;
            .cap-title {
                justify-content: center;
                padding-right: 0rem;
                h2 {
                    font-size: 2rem;
                    font-weight: 700;
                }
            }
            .cap-content {
                justify-content: center;
                margin: 1rem 0 0;
                .cap-wrap {
                    @include make-col(12);
                    @include make-col-ready();
                    .cap-item {
                        height: 320px;
                        margin-bottom: 1.5rem;
                        padding: 1.5rem 1rem;
                        text-align: center;
                    }
                }
                .servicePage-capTxt {
                    p {
                        font-size: 1rem;
                        // text-align: justify;
                    }
                }
            }
        }
    }
}


/*- Services Page - Art -*/

.servicePage_art {
    padding: 5% 0;
    overflow: hidden;
    background: #f1f4f9;
    .serviceArt-title {
        // @include make-row();
        h2 {
            @include make-col(12);
            @include make-col-ready();
            font-size: 3.5rem;
            font-weight: 600;
            margin-bottom: 60px;
            padding: 0;
            @include h2-deco-border;
            @media screen and (max-width: 1440px) {
                font-size: 3rem;
            }
        }
    }
    .serviceArt-content {
        @include make-row();
        margin: 0;
        padding: 0 15%;
        .serviceArt-wrap {
            @include make-col(6);
            @include make-col-ready();
            .serviceArt-item {
                position: relative;
                .serviceArt-h3 {
                    position: absolute;
                    right: 10%;
                    top: -7%;
                    display: flex;
                    align-items: center;
                    padding: .5rem 3rem;
                    // background: $primary;
                    background: linear-gradient(90deg, #4c8cc6 40%, #7cc8eb 100%);
                    z-index: 2;
                    h3 {
                        font-size: 2.5rem;
                        font-weight: 500;
                        color: white;
                        margin: 0;
                    }
                }
                .serviceArt-pic {
                    img {
                        width: 100%;
                        transform: scale(0.9);
                        transition: ease-in-out .3s;
                    }
                    &::after {
                        content: '';
                        width: 100%;
                        height: 100%;
                        border: solid 8px $primary;
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                }
                .serviceArt-txtdown {
                    position: absolute;
                    bottom: -2%;
                    left: 5%;
                    font-size: 1.5rem;
                    font-weight: 500;
                    color: $primary;
                    text-transform: uppercase;
                    opacity: 0;
                }
                &:hover {
                    .serviceArt-pic {
                        img {
                            transform: translate(-10%, -10%) scale(0.9);
                            transition: ease-in-out .3s;
                        }
                    }
                    .serviceArt-txtdown {
                        opacity: 1;
                        bottom: 2%;
                        transition: ease-in-out .3s;
                    }
                }
            }
            // .serviceArt-title {
            //     padding-left: 15%;
            //     h2 {
            //         font-size: 3.5rem;
            //         font-weight: 600;
            //     }
            // }
            &:nth-child(2) {
                margin-top: 5rem;
                padding-left: 3rem;
            }
            &:nth-child(3) {
                padding-top: 3rem;
                transform: translateX(3rem);
            }
            &:nth-child(4) {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
        @media screen and (max-width: 1300px) {
            padding: 0 10%;
            .serviceArt-wrap {
                .serviceArt-title {
                    padding-left: 15%;
                    h2 {
                        font-size: 3rem;
                    }
                }
            }
        }
        @media screen and (max-width: 1080px) {
            padding: 0 5%;
        }
        @media screen and (max-width: 820px) {
            padding: 0 10%;
            .serviceArt-wrap {
                @include make-col(12);
                @include make-col-ready();
                .serviceArt-item {
                    .serviceArt-h3 {
                        h3 {
                            font-size: 2rem;
                        }
                    }
                }
                &:nth-child(1) {
                    order: 2;
                }
                &:nth-child(2) {
                    margin-top: 2rem;
                    padding-left: 16px;
                    order: 3;
                }
                &:nth-child(3) {
                    padding-top: 2rem;
                    transform: translateX(0rem);
                    order: 4;
                }
                &:nth-child(4) {
                    order: 1;
                }
            }
        }
        @media screen and (max-width: 576px) {
            padding: 0 5%;
            .serviceArt-wrap {
                .serviceArt-item {
                    .serviceArt-h3 {
                        h3 {
                            font-size: 1.5rem;
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1300px) {
        padding: 150px 0 100px;
    }
    @media screen and (max-width: 1080px) {
        padding: 100px 0 50px;
    }
}


/*- Services Page - Project -*/

.servicePage_project {
    background: url('../images/servicePage-projectBg.png') no-repeat top right;
    background-size: cover;
    height: 600px;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    .project-container {
        margin: 0 auto;
        padding: 0 10%;
        max-width: 100%;
    }
    .project-title {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 5%;
        h2 {
            font-size: 3.5rem;
            font-weight: 500;
            color: white;
        }
    }
    .serviceProject-content {
        .project-slider-item {
            .slider-inner {
                background: white;
                padding: 1rem;
                // border-radius: 5px;
                .project-item-pic {
                    width: 100%;
                    height: 0;
                    padding-top: 100%;
                    position: relative;
                    overflow: hidden;
                    img {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        width: auto;
                        // height: 100%;
                        -webkit-transform: translate(-50%, -50%) scale(1.01);
                        transform: translate(-50%, -50%) scale(1.01);
                        transition: all 0.3s ease-in-out;
                    }
                }
            }
        }
    }
    .controls-project {
        list-style: none;
        width: 100%;
        position: absolute;
        display: flex;
        justify-content: space-between;
        padding: 0;
        .prev {
            background: url('../images/service-arrowleftBg.png')no-repeat right;
            background-size: 100%;
            align-items: center;
            display: flex;
            padding: 3rem 4rem 3rem 2rem;
            &:hover {
                .fas {
                    transform: translateX(-10px);
                    transition: ease-in-out .3s;
                }
            }
        }
        .next {
            background: url('../images/service-arrowrightBg.png')no-repeat right;
            background-size: 100%;
            padding: 3rem 2rem 3rem 4rem;
            &:hover {
                .fas {
                    transform: translateX(10px);
                    transition: ease-in-out .3s;
                }
            }
        }
        .fas {
            color: white;
            font-size: 2rem;
        }
    }
    .project-txt {
        padding-top: 2rem;
        p {
            margin: 0;
            font-size: 1.5rem;
            font-weight: 400;
            color: white;
        }
    }
    @media screen and (min-width: 1501px) {
        height: 800px;
    }
    @media screen and (max-width: 1300px) {
        .project-title {
            h2 {
                font-size: 3rem;
            }
        }
        .controls-project {
            .prev {
                padding: 2rem 3rem 2rem 1rem;
            }
            .next {
                padding: 2rem 1rem 2rem 3rem;
            }
        }
    }
    @media screen and (max-width: 1080px) {
        height: 800px;
        padding-top: 30px;
    }
    @media screen and (max-width: 820px) {
        height: 750px;
        padding-top: 50px;
        // margin-top: 2rem;
        .project-container {
            padding: 20%;
        }
        .project-title {
            h2 {
                text-align: right;
                font-size: 3rem;
            }
        }
    }
    @media screen and (max-width: 576px) {
        height: 600px;
        padding-top: 30px;
        .project-container {
            padding: 10%;
        }
        .project-title {
            h2 {
                text-align: right;
                font-size: 2rem;
                font-weight: 700;
            }
        }
        .project-txt {
            padding-top: 4rem;
            p {
                font-size: 1rem;
                text-align: center;
            }
        }
        .controls-project {
            transform: translateY(150px);
            .prev {
                padding: 1rem 2rem 1rem .5rem;
                .fas {
                    font-size: 1.2rem;
                }
            }
            .next {
                padding: 1rem .5rem 1rem 2rem;
                .fas {
                    font-size: 1.2rem;
                }
            }
        }
    }
}


/*- Services Page - Why -*/

.servicePage_why {
    background: url('../images/servicePage-whyBg.png') repeat center;
    background-size: cover;
    display: flex;
    align-items: center;
    overflow: hidden;
    animation: backanim 30s linear infinite;
    .whyRow {
        @include make-row();
        margin: 0;
        width: 100%;
        .servicePage-whyWrap {
            @include make-col(6);
            @include make-col-ready();
            margin: auto;
            padding-left: 5%;
            .why-title {
                h1 {
                    font-size: 3.5rem;
                    font-weight: 600;
                }
            }
            .why-content {
                margin: 3rem 0 0;
                .why-txt {
                    margin-bottom: 2rem;
                    padding-left: 1rem;
                    position: relative;
                    border-left: solid 10px $primary;
                    p {
                        margin: 0;
                        font-size: 1.3rem;
                        font-weight: 400;
                    }
                }
            }
        }
        .why-pic {
            @include make-col(6);
            @include make-col-ready();
            transform: translateY(10%);
            .why-rightPic {
                width: 100%;
                margin: 0 auto;
                animation-name: Shaking;
                animation-duration: 2s;
                animation-timing-function: linear;
                animation-iteration-count: infinite;
                @keyframes Shaking {
                    0% {
                        transform: translate(0, 0);
                    }
                    35% {
                        transform: translate(-15px, 15px);
                    }
                    70% {
                        transform: translate(-15px, 5px);
                    }
                    100% {
                        transform: translate(0, 0);
                    }
                }
            }
        }
        @media screen and (min-width: 1501px) {
            .servicePage-whyWrap {
                @include make-col(6);
                @include make-col-ready();
                margin: auto;
                padding-left: 5%;
            }
            .why-pic {
                @include make-col(6);
                @include make-col-ready();
                .why-rightPic {
                    width: 110%;
                }
            }
        }
        @media screen and (max-width: 1300px) {
            .servicePage-whyWrap {
                .why-title {
                    h1 {
                        font-size: 3rem;
                    }
                }
                .why-content {
                    margin: 2rem 0 0;
                    .why-txt {
                        p {
                            margin: 0;
                            font-size: 1.1rem;
                            font-weight: 400;
                        }
                    }
                }
            }
        }
        @media screen and (max-width: 1080px) {
            padding-top: 2rem;
            .servicePage-whyWrap {
                @include make-col(12);
                @include make-col-ready();
                margin: auto;
                padding: 10% 10% 5%;
                .why-content {
                    .why-txt {
                        p {
                            font-size: 1rem;
                        }
                    }
                }
            }
            .why-pic {
                @include make-col(12);
                @include make-col-ready();
                transform: translateY(-10%);
                .why-rightPic {
                    width: 80%;
                    margin: 0 auto;
                    padding-left: 80px;
                }
            }
        }
        @media screen and (max-width: 576px) {
            .servicePage-whyWrap {
                .why-title {
                    h1 {
                        font-size: 2rem;
                        font-weight: 700;
                    }
                }
            }
        }
        @media screen and (max-width: 414px) {
            .why-pic {
                .why-rightPic {
                    width: 105%;
                    padding-left: 90px;
                }
            }
        }
    }
    @keyframes backanim {
        0% {
            background-position: 1280px 1220px;
        }
        100% {
            background-position: 0 0;
        }
    }
}


/*- Services Page - mission -*/

.servicePage_mission {
    position: relative;
    margin-top: -5%;
    padding: 0 8%;
    .servicePage-missionWrap {
        height: 100%;
        .mission-row {
            @include make-row();
            margin: 0;
            transform: translateY(6%);
            border-top: solid 1px white;
            position: relative;
            .mission-pic {
                @include make-col(6);
                @include make-col-ready();
                .mission-photo {
                    transform: translateX(5%);
                    img {
                        width: 90%;
                    }
                }
            }
            .mission-content {
                @include make-col(6);
                @include make-col-ready();
                margin: auto 0;
                .mission-title {
                    margin-bottom: 3rem;
                    position: relative;
                    h2 {
                        font-size: 3.5rem;
                        font-weight: 600;
                        color: white;
                        position: relative;
                        animation-name: Swing;
                        /*動畫名稱，需與 keyframe 名稱對應*/
                        animation-duration: 2s;
                        /*動畫持續時間，單位為秒*/
                        animation-timing-function: linear;
                        animation-iteration-count: infinite;
                        @keyframes Swing {
                            0% {
                                transform: translate(0, 0);
                            }
                            20% {
                                transform: translate(-4px, 2px);
                            }
                            50% {
                                transform: translate(-2px, -3px);
                            }
                            70% {
                                transform: translate(-4px, 2px);
                            }
                            100% {
                                transform: translate(0, 0);
                            }
                        }
                    }
                    .glitch-window {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        color: #222222;
                        text-shadow: 2px 0 #F9F8F8, -1px 0 yellow, -2px 0 green;
                        overflow: hidden;
                        animation: mission 2s infinite linear alternate-reverse;
                        // background: red;
                    }
                    //Animation Keyframes
                    @keyframes mission {
                        0% {
                            clip: rect(31px, 9999px, 94px, 0);
                        }
                        10% {
                            clip: rect(112px, 9999px, 76px, 0)
                        }
                        20% {
                            clip: rect(85px, 9999px, 77px, 0);
                            left: 2px;
                            top: 2px;
                        }
                        30% {
                            clip: rect(27px, 9999px, 97px, 0)
                        }
                        40% {
                            clip: rect(90px, 9999px, 110px, 0)
                        }
                        50% {
                            clip: rect(120px, 9999px, 85px, 0);
                            left: 2px;
                            top: 0px;
                        }
                        60% {
                            clip: rect(99px, 9999px, 114px, 0)
                        }
                        70% {
                            clip: rect(34px, 9999px, 132px, 0);
                            left: 2px;
                            top: 0px;
                        }
                        80% {
                            clip: rect(98px, 9999px, 159px, 0)
                        }
                        90% {
                            clip: rect(43px, 9999px, 102px, 0)
                        }
                        100% {
                            clip: rect(82px, 9999px, 64px, 0);
                            left: 0;
                            top: 0;
                        }
                    }
                }
                .mission-txt {
                    border-left: solid 15px white;
                    width: 80%;
                    p {
                        margin: 0;
                        padding-left: 1rem;
                        font-size: 1.3rem;
                        font-weight: 400;
                        color: white;
                        // text-align: justify;
                    }
                }
            }
            &::after {
                content: '';
                background: white;
                background-size: 100%;
                height: 10px;
                width: 10px;
                position: absolute;
                top: -5px;
                right: -2%;
            }
        }
        &::after {
            content: '';
            background: white;
            background-size: 100%;
            height: 50%;
            width: 1px;
            position: absolute;
            bottom: 0;
            right: 5%;
        }
        @media screen and (min-width: 1501px) {
            .mission-row {
                .mission-content {
                    .mission-txt {
                        border-left: solid 18px white;
                        width: 80%;
                        p {
                            margin: 0;
                            padding-left: 1rem;
                            font-size: 1.5rem;
                            font-weight: 400;
                        }
                    }
                }
            }
        }
        @media screen and (max-width: 1300px) {
            .mission-row {
                .mission-content {
                    .mission-title {
                        margin-bottom: 2rem;
                        h2 {
                            font-size: 3rem;
                        }
                    }
                    .mission-txt {
                        border-left: solid 15px white;
                        width: 80%;
                        p {
                            margin: 0;
                            padding-left: 1rem;
                            font-size: 1rem;
                            font-weight: 400;
                        }
                    }
                }
            }
        }
        @media screen and (max-width: 1080px) {
            display: flex;
            align-items: center;
            .mission-row {
                .mission-pic {
                    @include make-col(7);
                    @include make-col-ready();
                    .mission-photo {
                        transform: translateX(-5%);
                        img {
                            width: 100%;
                        }
                    }
                }
                .mission-content {
                    @include make-col(5);
                    @include make-col-ready();
                }
            }
            &::after {
                right: 0%;
            }
        }
        @media screen and (max-width: 820px) {
            .mission-row {
                height: 100%;
                .mission-pic {
                    @include make-col(12);
                    @include make-col-ready();
                    order: 2;
                    .mission-photo {
                        img {
                            width: 100%;
                        }
                    }
                }
                .mission-content {
                    @include make-col(12);
                    @include make-col-ready();
                    order: 1;
                    padding-left: 4rem;
                }
            }
        }
        @media screen and (max-width: 576px) {
            .mission-row {
                .mission-content {
                    .mission-title {
                        h2 {
                            font-size: 2rem;
                            font-weight: 700;
                        }
                    }
                }
            }
        }
    }
    &::before {
        content: '';
        background: $primary;
        background-size: 100%;
        height: 100%;
        width: 63%;
        position: absolute;
        top: 0;
        right: 0;
    }
    @media screen and (max-width: 1080px) {
        height: 500px;
        margin-top: -20%;
    }
    @media screen and (max-width: 820px) {
        height: 850px;
        &::before {
            width: 90%;
        }
    }
    @media screen and (max-width: 576px) {
        height: 700px;
    }
}


/*- Services Page - touch -*/

.servicePage_touch {
    background: url('../images/servicePage-touchBg.png')no-repeat top left;
    background-size: cover;
    width: 100%;
    padding: 1rem 0;
    position: relative;
    overflow: hidden;
    .touch-row {
        @include make-row();
        margin: 0;
        width: 100%;
    }
    .touch-pic {
        @include make-col(5);
        @include make-col-ready();
        width: 25%;
        animation-name: touch-scale;
        /*動畫名稱，需與 keyframe 名稱對應*/
        animation-duration: 2.5s;
        /*動畫持續時間，單位為秒*/
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
        @keyframes touch-scale {
            0% {
                transform: scale(.9);
            }
            50% {
                transform: scale(1);
            }
            100% {
                transform: scale(.9);
            }
        }
        .touch-pic-wrap {
            position: relative;
            transform: translate(-10%, 10%);
        }
    }
    .touch-wrap {
        @include make-col(6);
        @include make-col-ready();
        margin: auto;
        padding-left: 3rem;
        .touch-item {
            padding-left: 13%;
            padding-top: 5%;
            .touch-txt {
                p {
                    font-size: 3rem;
                    font-weight: 800;
                    letter-spacing: 3px;
                }
            }
        }
        .touch-cta {
            display: inline-block;
            transform: translateY(10%);
            margin-top: 3rem;
            animation-name: Scaling;
            /*動畫名稱，需與 keyframe 名稱對應*/
            animation-duration: 1s;
            /*動畫持續時間，單位為秒*/
            animation-timing-function: ease-in-out;
            animation-iteration-count: infinite;
            @keyframes Scaling {
                0% {
                    transform: scale(1);
                }
                50% {
                    transform: scale(1.1);
                    opacity: .6;
                }
                100% {
                    transform: scale(1);
                }
            }
            .touch-btn {
                padding: .8rem;
                font-size: 1rem;
                background: $primary;
                color: white;
                text-decoration: none;
            }
        }
    }
    @media screen and (min-width: 1501px) {
        .touch-pic {
            .touch-pic-wrap {
                transform: translateY(15%);
            }
        }
        .touch-wrap {
            padding-left: 5%;
            .touch-txt {
                p {
                    font-size: 3.5rem;
                }
            }
        }
        .touch-cta {
            .touch-btn {
                padding: 1rem;
                font-size: 1.5rem;
            }
        }
    }
    @media screen and (max-width: 1300px) {
        .touch-wrap {
            padding-left: 2rem;
            .touch-item {
                padding-left: 8%;
                padding-top: 5%;
                .touch-txt {
                    p {
                        font-size: 2.2rem;
                        font-weight: 800;
                        letter-spacing: 3px;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1080px) {
        padding: 3rem 0;
    }
    @media screen and (max-width: 820px) {
        height: 700px;
        .touch-pic {
            @include make-col(12);
            @include make-col-ready();
            .touch-pic-wrap {
                transform: translate(0, 0);
                text-align: center;
                img {
                    width: 80%;
                }
            }
        }
        .touch-wrap {
            @include make-col(12);
            @include make-col-ready();
            padding-top: 3rem;
        }
    }
    @media screen and (max-width: 576px) {
        height: 600px;
        .touch-pic {
            .touch-pic-wrap {
                transform: translate(0, 0);
                text-align: center;
                img {
                    width: 100%;
                }
            }
        }
        .touch-wrap {
            @include make-col(12);
            @include make-col-ready();
            padding-top: 3rem;
            .touch-item {
                .touch-txt {
                    p {
                        font-size: 2rem;
                        font-weight: 700;
                        letter-spacing: initial;
                    }
                }
            }
            .touch-cta {
                margin-top: 1rem;
            }
        }
    }
}


/*----------------------------------------------------- Games Page -------------------------------------------------------*/


/*- Games Page - Banner -*/

.gamePage_banner {
    @include banner-page;
    background: transparent linear-gradient(250deg, #fff 0%, #7BB8ED 30%, #F1AF8E 100%);
    .gamePage-bannerWrap {
        background: url('../images/gamePage-bannerBg.png') no-repeat left top;
        @include banner-wrap;
    }
    .gamePage-pic {
        @include banner-character-position;
        @include banner-move-ani;
        width: 45%;
        @media screen and (max-width: 1680px) {
            width: 40%;
            top: 10%;
            right: 0%;
        }
        @media screen and (max-width: 1280px) {
            // width: 36%;
            top: 0%;
        }
        @include media-breakpoint-down(lg) {
            width: 65%;
            top: 30%;
            right: 5%;
        }
        @include media-breakpoint-down(sm) {
            width: 75%;
            top: 35%;
        }
    }
}


/*- Games Page - Games -*/

.gamePage_games {
    position: relative;
    overflow: hidden;
    margin-top: -13%;
    background: url('../images/servicePage-capBg.png') no-repeat top right;
    background-size: cover;
    .gamePage-gameWrap {
        padding: 10% 0 5%;
        .game-title {
            display: flex;
            justify-content: flex-end;
            padding-right: 4rem;
            h2 {
                font-size: 3.5rem;
                font-weight: 600;
                position: relative;
                @include h2-deco-border;
            }
        }
        .game-content {
            margin: 5rem 0 0;
            margin-left: 0 !important;
            margin-right: 0 !important;
            @include make-row();
            .game-wrap {
                @include make-col(4);
                @include make-col-ready();
                margin-bottom: 2rem;
                position: relative;
                overflow: hidden;
                padding: 1rem;
                .game-item {
                    background: #D9ECF4;
                    background-size: 100%;
                    padding: 1rem;
                    border-bottom: solid 2px $primary;
                    position: relative;
                    transition: ease-in-out .3s;
                    .item-content {
                        position: relative;
                        .game-img {
                            width: 100%;
                            height: 0;
                            padding-top: 100%;
                            position: relative;
                            overflow: hidden;
                            img {
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                width: auto;
                                height: 100%;
                                -webkit-transform: translate(-50%, -50%) scale(1.01);
                                transform: translate(-50%, -50%) scale(1.01);
                                // transition: all 0.3s ease-in-out;
                            }
                            .mask {
                                background: rgba(0, 0, 0, .4);
                                background-size: 100%;
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                transition: ease-in-out .3s;
                            }
                        }
                        .game-name {
                            position: absolute;
                            top: 46%;
                            left: 0%;
                            width: 100%;
                            h3 {
                                text-transform: uppercase;
                                color: white;
                                font-size: 1.5rem;
                                font-weight: 600;
                                text-align: center;
                            }
                        }
                        .game-btns {
                            position: absolute;
                            top: 0%;
                            bottom: 0%;
                            left: 0%;
                            right: 0%;
                            width: 100%;
                            display: none;
                            transition: ease-in-out .8s;
                            .btn-content {
                                display: flex;
                                justify-content: center;
                                flex-direction: column;
                                height: 100%;
                                .btn-wrap {
                                    margin: .15rem auto;
                                    width: 50%;
                                    @include opacity-hover-ani;
                                    padding: 1px;
                                    transition: ease-in-out .8s;
                                    a {
                                        transition: ease-in-out .3s;
                                        display: block;
                                        padding: .15rem;
                                    }
                                }
                            }
                        }
                    }
                    .games-md-card {
                        display: none;
                    }
                    &:hover {
                        box-shadow: -2px 2px 15px 0px rgba(0, 0, 0, 0.2);
                        transition: ease-in-out .3s;
                    }
                }
                &:hover {
                    .game-item {
                        .item-content {
                            .game-img {
                                .mask {
                                    background: rgba(53, 89, 155, .7);
                                    // transition: ease-in .2s;
                                }
                            }
                            .game-name {
                                display: none;
                            }
                            .game-btns {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                flex-direction: column;
                                display: inherit;
                                transition: ease-in .2s;
                            }
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1300px) {
        margin-top: -120px;
        .gamePage-gameWrap {
            .game-title {
                h2 {
                    font-size: 3rem;
                }
            }
        }
    }
    @media screen and (max-width: 1080px) {
        .gamePage-gameWrap {
            padding: 150px 0 5%;
            .game-content {
                margin: 3rem 0 0;
                .game-wrap {
                    @include make-col(6);
                    @include make-col-ready();
                    .game-item {
                        .item-content {
                            .game-btns {
                                display: none;
                            }
                        }
                        .games-md-card {
                            padding-top: 1rem;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            .card-content {
                                display: flex;
                                width: 250px;
                                justify-content: center;
                            }
                            .card-wrap {
                                text-align: center;
                                @include opacity-hover-ani;
                                a {
                                    display: block;
                                    padding: 10px;
                                }
                            }
                        }
                    }
                    &:hover {
                        .game-item {
                            .item-content {
                                .game-img {
                                    .mask {
                                        background: rgba(0, 0, 0, .4);
                                    }
                                }
                                .game-name {
                                    display: initial;
                                }
                                .game-btns {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 820px) {
        margin-top: -150px;
        .gamePage-gameWrap {
            padding: 100px 0 5%;
            .game-content {
                margin: 3rem 0 0;
                .game-wrap {
                    margin-bottom: 1rem;
                }
            }
        }
    }
    @media screen and (max-width: 576px) {
        margin-top: -170px;
        .gamePage-gameWrap {
            .game-title {
                h2 {
                    font-size: 2rem;
                    font-weight: 700;
                }
            }
            .game-content {
                margin: 1rem 0 0;
                .game-wrap {
                    @include make-col(12);
                    @include make-col-ready();
                }
            }
        }
    }
}


/*----------------------------------------------------- Contact Page -------------------------------------------------------*/


/*- Contact Page - Banner -*/

.contactPage_banner {
    @include banner-page;
    background: transparent linear-gradient(250deg, #15343B 0%, #194E56 30%, #307179 55%, #EFFFAA 100%);
    .contactPage-bannerWrap {
        background: url('../images/contactPage-bannerBg.png') no-repeat left top;
        @include banner-wrap;
    }
    .contactPage-pic {
        @include banner-character-position;
        @include banner-move-ani;
        width: 70%;
        right: -20%;
        @media screen and (max-width: 1440px) {
            width: 45%;
            top: 10%;
            right: 0%;
        }
        @media screen and (max-width: 1280px) {
            top: 10%;
        }
        @include media-breakpoint-down(lg) {
            width: 90%;
            top: 30%;
        }
        @include media-breakpoint-down(sm) {
            top: 40%;
        }
    }
}


/*- Contact Page - content -*/

.contactPage_contact {
    position: relative;
    overflow: hidden;
    margin-top: -13%;
    // background: url('../images/contactPage-capBg.svg') no-repeat top right;
    background: url('../images/contactPage-capBg.png') no-repeat top right;
    background-size: cover;
    @media screen and (max-width: 1440px) {
        margin-top: -12%;
    }
    .contactPage-contactWrap {
        padding: 20% 0 15% 0;
        // @media screen and (max-width: 414px) {
        //     padding: 20% 0 15% 0;
        // }
        .contact-row {
            display: flex;
            justify-content: center;
            .contact-form {
                background: #ffffff;
                padding: 9% 4% 4% 4%;
                position: relative;
                @media screen and (max-width: 414px) {
                    padding: 15% 4% 4% 4%;
                }
                strong {
                    color: $primary;
                    @include opacity-hover-ani;
                }
                .form-form {
                    margin-top: 1rem;
                    .form-group {
                        border-bottom: solid 1px #000;
                        margin: 2rem .5rem;
                        input {
                            border: none;
                            padding: .375rem 0;
                            &::placeholder {
                                color: #797979;
                                font-size: .8rem;
                            }
                        }
                        textarea {
                            border-color: #000;
                            &::placeholder {
                                color: #797979;
                                font-size: .8rem;
                            }
                        }
                        &:last-child {
                            border: none;
                            margin-top: 2rem;
                        }
                    }
                }
                .form-btn {
                    display: flex;
                    justify-content: center;
                    .contact-btn {
                        padding: .8rem 2.5rem;
                        font-size: 1rem;
                        background: $primary;
                        color: white;
                        text-decoration: none;
                        border: none;
                        position: relative;
                        &::before {
                            position: absolute;
                            top: 0;
                            left: -75%;
                            z-index: 2;
                            display: block;
                            content: '';
                            width: 50%;
                            height: 100%;
                            background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
                            background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
                            -webkit-transform: skewX(-25deg);
                            transform: skewX(-25deg);
                        }
                        &:hover {
                            transform: scale(1.05);
                            transition: ease-in .2s;
                            &::before {
                                -webkit-animation: shine .75s;
                                animation: shine .75s;
                            }
                            @-webkit-keyframes shine {
                                100% {
                                    left: 125%;
                                }
                            }
                            @keyframes shine {
                                100% {
                                    left: 125%;
                                }
                            }
                        }
                    }
                }
            }
            .form-title {
                position: absolute;
                top: -45px;
                left: -60px;
                @media screen and (max-width: 768px) {
                    // position: unset;
                    top: -25px;
                    left: -10px;
                }
                h2 {
                    font-size: 3.5rem;
                    letter-spacing: 2px;
                    @media screen and (max-width: 768px) {
                        font-size: 2rem;
                    }
                }
            }
        }
    }
}


/*----------------------------------------------------- Gallery Page -------------------------------------------------------*/


/*- Gallery Page - Banner -*/

.galleryPage_banner {
    @include banner-page;
    background: transparent linear-gradient(250deg, #fff 0%, rgba(132, 135, 226, 0.521569) 30%, #F4ACC7 100%);
    .galleryPage-bannerWrap {
        background: url('../images/galleryPage-bannerBg.png') no-repeat left top;
        @include banner-wrap;
    }
    .galleryPage-pic {
        @include banner-character-position;
        @include banner-move-ani;
        @media screen and (max-width: 1440px) {
            // width: 45%;
            top: 4%;
            right: 3%;
        }
        @include media-breakpoint-down(lg) {
            width: 80%;
            top: 20%;
        }
        @include media-breakpoint-down(sm) {
            top: 30%;
        }
    }
}


/*- Gallery Page - Art -*/

.galleryPage_gallery {
    position: relative;
    overflow: hidden;
    margin-top: -13%;
    background: url('../images/servicePage-capBg.png') no-repeat top right;
    background-size: cover;
    .galleryPage-galleryWrap {
        padding: 10% 0 5%;
        .gallery-title {
            display: flex;
            justify-content: flex-end;
            padding-right: 4rem;
            h2 {
                font-size: 3.5rem;
                font-weight: 600;
                position: relative;
                @include h2-deco-border;
            }
        }
        .back {
            color: $primary;
            font-size: 1rem;
        }
        .gallery-wrap {
            .gallery-row {
                grid-gap: 15px;
                display: flex;
                flex-wrap: wrap;
                display: grid;
                margin: 10% auto 5%;
                grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
                grid-auto-rows: minmax(150px, auto);
                .gallery-item {
                    flex: 1 1 200px;
                    overflow: hidden;
                    // border-radius: 5px;
                    .gallery-outside {
                        width: 100%;
                        height: 0;
                        padding-top: 100%;
                        position: relative;
                        overflow: hidden;
                        background: $primary;
                        img {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            width: auto;
                            height: 100%;
                            -webkit-transform: translate(-50%, -50%) scale(1.01);
                            transform: translate(-50%, -50%) scale(1.01);
                            transition: all 0.3s ease-in-out;
                        }
                    }
                    @include gallery-hover-ani;
                    &:nth-child(2n+1) {
                        grid-row-end: span 2;
                        .gallery-outside {
                            width: 100%;
                            height: 0;
                            padding-top: calc(200% + 15px);
                            position: relative;
                            overflow: hidden;
                            background: $primary;
                        }
                        @include gallery-hover-ani;
                    }
                    &:nth-child(6n+1) {
                        grid-column-end: span 2;
                        grid-row-end: span 2;
                        .gallery-outside {
                            width: 100%;
                            height: 0;
                            padding-top: 100%;
                            position: relative;
                            overflow: hidden;
                            background: $primary;
                        }
                        @include gallery-hover-ani;
                    }
                }
                @supports (display: grid) {
                    .wrapper>* {
                        margin: 0;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1300px) {
        .galleryPage-galleryWrap {
            .gallery-title {
                h2 {
                    font-size: 3rem;
                }
            }
        }
    }
    @media screen and (max-width: 1080px) {
        .galleryPage-galleryWrap {
            .gallery-wrap {
                .gallery-row {
                    .gallery-item {
                        &:nth-child(7) {
                            grid-column-end: span 1;
                            grid-row-end: span 1;
                        }
                        &:nth-child(8) {
                            grid-column-end: span 2;
                            grid-row-end: span 2;
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 820px) {
        .galleryPage-galleryWrap {
            padding: 100px 0 5%;
        }
    }
    @media screen and (max-width: 576px) {
        margin-top: -180px;
        .galleryPage-galleryWrap {
            .gallery-title {
                h2 {
                    font-size: 2rem;
                    font-weight: 700;
                }
            }
            .gallery-wrap {
                .gallery-row {
                    .gallery-item {
                        &:nth-child(n) {
                            grid-column-end: span 1;
                            grid-row-end: span 1;
                        }
                    }
                }
            }
        }
    }
}

.galleryPage-list {
    .item-name {
        @include card-title-center;
    }
}

//密碼頁
.galleryPage-passwordWrap {
    background: url('../images/gallery/gallery-password-bg.png')no-repeat center #000;
    height: 100vh;
    .content {
        @include position-center;
        @include flex-center;
    }
    h3 {
        color: #fff;
        margin: 25px 0 12.5px 0;
    }
    form {
        @include flex-center;
    }
    .form-form {
        .form-group {
            border-bottom: solid 1px #FFFFFF;
            width: 360px;
            input {
                border: none;
                background: unset;
                &::placeholder {
                    color: #ffffff80;
                }
            }
            .form-control {
                text-align: center;
                color: #FFFFFF;
            }
        }
    }
    .btn-light {
        color: #ffffff;
        background: #ffffff40;
        border: 0.5px solid #FFFFFF;
        margin: 25px 0 0;
        padding: .375rem 1.25rem;
        @include opacity-hover-ani;
    }
}